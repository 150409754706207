/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

// Modules
import audio from './modules/audio';
import tag from './modules/tag';
import organization from './modules/organization';
import moonlightDevice from './modules/moonlight-device';
import category from './modules/category';
import subCategory from './modules/sub-category';
import auth from './modules/auth';
import user from './modules/user';
import group from './modules/group';
import groupAudioApproval from './modules/group-audio-approval';
import account from './modules/account';
import audioAccount from './modules/audio-account';
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  /**
   * Assign the modules to the store.
   */
  modules: {
    group,
    audio,
    tag,
    organization,
    moonlightDevice,
    category,
    subCategory,
    auth,
    user,
    groupAudioApproval,
    account,
    audioAccount
  },

  /**
   * If strict mode should be enabled.
   */
  strict: debug,

  /**
   * Plugins used in the store.
   */
  plugins: debug ? [createLogger()] : [],
});
