import request from '@/utils/request'

function login (params) {
  return request({
    url: `/auth/login`,
    method: 'post',
    data: params
  })
}
function refresh (params) {
    return request({
      url: `/auth/refresh`,
      method: 'post',
      data: params
    })
  }
  function updatePassword (params) {
    return request({
      url: `/auth/password`,
      method: 'put',
      data: params
    })
  }
export default {
    login,
    refresh,
    updatePassword
}
