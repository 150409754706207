export const SET_LIST = 'SET_LIST';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_MAX = 'SET_MAX';
export const SET_OFFSET = 'SET_OFFSET';
export const APPEND = 'APPEND';
export const UPDATE = 'UPDATE';
export const CLEAR = 'CLEAR';
export default {
  SET_LIST,
  SET_TOTAL,
  SET_OFFSET,
  SET_MAX,
  APPEND,
  UPDATE,
  CLEAR
};
