import {
  SET_LIST,
  SET_TOTAL,
  SET_OFFSET,
  SET_MAX,
  SET_ORDER,
  SET_SORT,
  CLEAR
} from './mutation-types';

export default {
  [SET_LIST] (state,
    tags
  ) {
    state['tags'] = [
      ...tags
    ];
  },
  [SET_TOTAL] (state,
    total
  ) {
    state['total'] = total;
  },
  [SET_OFFSET] (state,
    offset
  ) {
    state['offset'] = offset;
  },
  [SET_MAX] (state,
    max
  ) {
    state['max'] = max;
  },
  [SET_SORT] (state,
    sort
  ) {
    state['sort'] = sort;
  },
  [SET_ORDER] (state,
    order
  ) {
    state['order'] = order;
  },
  [CLEAR] (state
    ) {
      state['tags'] = [];
      state['total'] = 0;
      state['max'] = 10;
      state['offset'] = 0;
      state['sort'] = 'id';
      state['order'] = 'ascending';
    },
};
