import request from '@/utils/request'

function list (params) {
  const qsParams = new URLSearchParams();
  for (var key in params) {
    qsParams.append(key,params[key])
  }
  return request({
    url: `/users?${qsParams.toString()}`,
    method: 'get',
    data: params
  })
}
function add (params) {
  return request({
    url: `/users`,
    method: 'post',
    data: params
  })
}
function update (params) {
  return request({
    url: `/users/${params.id}`,
    method: 'put',
    data: params
  })
}
function remove (user_id) {
  return request({
    url: `/users/${user_id}`,
    method: 'delete'
  })
}
function search (params) {
  return request({
    url: `/users/search`,
    method: 'post',
    data: params
  })
}
export default {
    list,
    add,
    update,
    remove,
    search
}
