import request from '@/utils/request'

function list (params) {
  const qsParams = new URLSearchParams();
  for (var key in params) {
    qsParams.append(key,params[key])
  }
  return request({
    url: `/group?${qsParams.toString()}`,
    method: 'get',
    data: params
  })
}
function add (params) {
  return request({
    url: `/group`,
    method: 'post',
    data: params
  })
}

function update (params) {
  return request({
    url: `/group/${params.id}`,
    method: 'put',
    data: params
  })
}
function remove (tag_id) {
  return request({
    url: `/group/${tag_id}`,
    method: 'delete'
  })
}
export default {
    list,
    add,
    update,
    remove
}
