/* ============
 * Getters for the global module
 * ============
 *
 * The getters that are available on the
 * global module.
 */
export const audios = (state) => {
  return state.audios
}
export const total = (state) => {
  return state.total
}
export const offset = (state) => {
  return state.offset
}
export const max = (state) => {
  return state.max
}
export const getAudioById = (state) => (id) => {
  return state.audios.find(audio => audio.id === id)
}
export const sort = (state) => {
  return state.sort
}
export const order = (state) => {
  return state.order
}
export default {
  audios,
  total,
  offset,
  max,
  getAudioById,
  sort,
  order,
}
