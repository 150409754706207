/* ============
 * Getters for the global module
 * ============
 *
 * The getters that are available on the
 * global module.
 */
export const moonlightDevices = (state) => {
  return state.moonlightDevices
}
export const total = (state) => {
  return state.total
}
export const offset = (state) => {
  return state.offset
}
export const max = (state) => {
  return state.max
}
export const getDeviceById = (state) => (id) => {
  return state.moonlightDevices.find(moonlightDevice => moonlightDevice.id === id)
}
export const sort = (state) => {
  return state.sort
}
export const order = (state) => {
  return state.order
}
export default {
  moonlightDevices,
  total,
  offset,
  max,
  getDeviceById,
  sort,
  order
}
