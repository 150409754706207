import request from '@/utils/request'

function list (params) {
  const qsParams = new URLSearchParams();
  for (var key in params) {
    qsParams.append(key,params[key])
  }
  return request({
    url: `/tag?${qsParams.toString()}`,
    method: 'get',
    data: params
  })
}
function add (params) {
  return request({
    url: `/tag`,
    method: 'post',
    data: params
  })
}
function search (params) {
  return request({
    url: `/tag/search`,
    method: 'post',
    data: params
  })
}
function update (params) {
  return request({
    url: `/tag/${params.id}`,
    method: 'put',
    data: params
  })
}
function remove (tag_id) {
  return request({
    url: `/tag/${tag_id}`,
    method: 'delete'
  })
}
export default {
    list,
    add,
    search,
    update,
    remove
}
