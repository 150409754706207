import request from '@/utils/request'


function post (params) {
  return request({
    url: `/audio-account`,
    method: 'post',
    data: params
  })
}
function list (params) {
  const qsParams = new URLSearchParams();
  for (var key in params) {
    qsParams.append(key,params[key])
  }
 
  return request({
    url: `/audio-account?${qsParams.toString()}`,
    method: 'get',
    data: params
  })
}
function remove (params) {
  return request({
    url: `/audio-account/${params.audio_id}/${params.account_id}`,
    method: 'delete'
  })
}
function batchDelete (params) {
  return request({
    url: `/audio-account/batchDelete`,
    method: 'post',
    data: params
  })
}
function batchUpdate (params) {
  return request({
    url: `/audio-account/batchUpdate`,
    method: 'post',
    data: params
  })
}

export default {
    post,
    list,
    remove,
    batchDelete,
    batchUpdate
}
