/* ============
 * Getters for the global module
 * ============
 *
 * The getters that are available on the
 * global module.
 */
export const subCategories = (state) => {
  return state.subCategories
}
export const total = (state) => {
  return state.total
}
export const offset = (state) => {
  return state.offset
}
export const max = (state) => {
  return state.max
}
export const getSubCategoryById = (state) => (id) => {
  return state.subCategories.find(subCategory => subCategory.id === id)
}

export default {
  subCategories,
  total,
  offset,
  max,
  getSubCategoryById
}
