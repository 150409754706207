import {
  SET_LIST,
  SET_TOTAL,
  SET_OFFSET,
  SET_MAX,
  APPEND,
  UPDATE,
  CLEAR
} from './mutation-types';
function findNode(nodes, id) {
  for(var node of nodes){
    if (node.id === id){
      return node;
    } else {
      if (node.children.length > 0) {
        var r =  findNode(node.children,id);
        if(r) {
          return r;
        }
      }
    }
  }
  return null;
}
export default {
  [SET_LIST] (state,
    items
  ) {
    state['categories'] = [
      ...items
    ];
  },
  [SET_TOTAL] (state,
    total
  ) {
    state['total'] = total;
  },
  [SET_OFFSET] (state,
    offset
  ) {
    state['offset'] = offset;
  },
  [SET_MAX] (state,
    max
  ) {
    state['max'] = max;
  },
  [APPEND] (state,
    item
  ) {
    if(!item.children) {
      item.children = [];
    }
    if(item.parent) {
      findNode(state['categories'],item.parent.id).children.push(item)
    } else {
      state['categories'].push(item);
    }
    
  },
  [UPDATE] (state,
    item
  ) {
    if(item.id) {
      var old = findNode(state['categories'],item.id);
      old.name = item.name;
    } else {
      state['categories'].push(item);
    }
    
  },
  [CLEAR] (state
    ) {
      state['categories'] = [];
      state['total'] = 0;
      state['max'] = 10;
      state['offset'] = 0;
    },
};
