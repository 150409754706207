import request from '@/utils/request'

function list (params) {
  const qsParams = new URLSearchParams();
  for (var key in params) {
    qsParams.append(key,params[key])
  }
 
  return request({
    url: `/group-audio-approval/${params.id}?${qsParams.toString()}`,
    method: 'get',
    data: params
  })
}
function update (params) {
    return request({
      url: `/group-audio-approval/${params.id}`,
      method: 'put',
      data: params
    })
}
export default {
    list,
    update
}
