import {
  SET_LIST,
  SET_TOTAL,
  SET_OFFSET,
  SET_MAX,
  SET_SORT,
  SET_ORDER,
  CLEAR
} from './mutation-types';

export default {
  [SET_LIST] (state,
    accounts
  ) {
    state['accounts'] = [
      ...accounts
    ];
  },
  [SET_TOTAL] (state,
    total
  ) {
    state['total'] = total;
  },
  [SET_OFFSET] (state,
    offset
  ) {
    state['offset'] = offset;
  },
  [SET_MAX] (state,
    max
  ) {
    state['max'] = max;
  },
  [SET_SORT] (state,
    sort
  ) {
    state['sort'] = sort;
  },
  [SET_ORDER] (state,
    order
  ) {
    state['order'] = order;
  },
  [CLEAR] (state
  ) {
    state['accounts'] = [];
    state['total'] = 0;
    state['max'] = 10;
    state['offset'] = 0;
    state['sort'] = 'no';
    state['order'] = 'ascending';
  },
};
