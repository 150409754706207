export const SET_LIST = 'SET_LIST';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_MAX = 'SET_MAX';
export const SET_OFFSET = 'SET_OFFSET';
export default {
  SET_LIST,
  SET_TOTAL,
  SET_OFFSET,
  SET_MAX
};
