<template>
  <div id="app">
    <transition name="fade">
      <router-view></router-view>
    </transition>
    <vuedal></vuedal>
  </div>
</template>

<script>
import { Component as Vuedal } from 'vuedals-submit';
export default {
  name: 'App',
  components: {
    Vuedal
  },
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
