/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  photoURL: '',
  isSignIn: false,
  email:'',
  displayName:'', 
  isAdmin: false,
  access_token: '',
  access_token_exp: 0,
  user_id: 0,
  role: ''
};
