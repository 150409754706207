export const SET_LIST = 'SET_LIST';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_MAX = 'SET_MAX';
export const SET_OFFSET = 'SET_OFFSET';
export const SET_SORT = 'SET_SORT';
export const SET_ORDER = 'SET_ORDER';
export const CLEAR = 'CLEAR';
export default {
  SET_LIST,
  SET_TOTAL,
  SET_OFFSET,
  SET_MAX,
  SET_SORT,
  SET_ORDER,
  CLEAR
};
