import request from '@/utils/request'
function list (params) {
  const qsParams = new URLSearchParams();
  for (var key in params) {
    qsParams.append(key,params[key])
  }
  return request({
    url: `/account?${qsParams.toString()}`,
    method: 'get',
    data: params
  })
}

function add (params) {
  return request({
    url: `/account`,
    method: 'post',
    data: params
  })
}
function update (params) {
  return request({
    url: `/account/${params.id}`,
    method: 'put',
    data: params
  })
}
function remove (tag_id) {
  return request({
    url: `/account/${tag_id}`,
    method: 'delete'
  })
}
function batch (params) {
  return request({
    url: `/account/batch`,
    method: 'post',
    data: params
  })
}
function batchDelete (params) {
  return request({
    url: `/account/batchDelete`,
    method: 'post',
    data: params
  })
}
function cjxfSummary () {
  return request({
    url: `/account/cjxfSummary`,
    method: 'post',
  })
}
function download (params) {
  const qsParams = new URLSearchParams();
  for (var key in params) {
    qsParams.append(key,params[key])
  }
 
  return request({
    url: `/account/export?${qsParams.toString()}`,
    method: 'get',
    responseType: 'blob',
    data: params
  })
}
export default {
    list,
    add,
    update,
    remove,
    batch,
    batchDelete,
    download,
    cjxfSummary
}
