import request from '@/utils/request'

function list (params) {
  return request({
    url: `/sub-category?max=${params.max}&offset=${params.offset}&category_id=${params.category_id}`,
    method: 'get',
    data: params
  })
}
function add (params) {
  return request({
    url: `/sub-category`,
    method: 'post',
    data: params
  })
}
function update (params) {
  return request({
    url: `/sub-category/${params.id}`,
    method: 'put',
    data: params
  })
}
function remove (subCategory_id) {
  return request({
    url: `/sub-category/${subCategory_id}`,
    method: 'delete'
  })
}
function validateName (params) {
  return request({
    url: `/sub-category/validate/name`,
    method: 'post',
    data: params
  })
}

export default {
    list,
    add,
    update,
    remove,
    validateName
}
