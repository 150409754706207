/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

// import Vue from 'vue';
// import store from '@/store';
// import * as types from './mutation-types';
/*
import API from '@/api/';
export const fetchPlanList = ({
  commit
}) => {
  return new Promise((resolve) => {
    API.plan.fetchPlanList().then(res => {
      if (res.status === 200) {
        commit(`plan/SET_PLANLIST`, {
          items: res.data
        }, {
          root: true
        })
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }
    })
  });
};
*/
import API from '@/api/'

export const post = ({ commit },params) => {
  return new Promise((resolve) => {
    API.audioAccount.post(params).then(res => {
      if (res.status === 201) {
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export const list = ({ commit },params) => {
  return new Promise((resolve) => {
    API.audioAccount.list(params).then(res => {
      if (res.status === 200) {
        commit(`audioAccount/SET_LIST`, res.data[0], {
          root: true
        });
        commit(`audioAccount/SET_TOTAL`, res.data[1], {
          root: true
        });
        commit(`audioAccount/SET_OFFSET`, params.offset, {
          root: true
        });
        commit(`audioAccount/SET_MAX`, params.max, {
          root: true
        });
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export const remove = ({ commit },params) => {
  return new Promise((resolve) => {
    API.audioAccount.remove(params).then(res => {
      if (res.status === 200) {
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export const batchDelete = ({ commit }, params) => {
  return new Promise((resolve) => {
    API.audioAccount.batchDelete(params).then(res => {
      resolve(res);
    })
  })
}
export const batchUpdate = ({ commit }, params) => {
  return new Promise((resolve) => {
    API.audioAccount.batchUpdate(params).then(res => {
      resolve(res);
    })
  })
}
export default {
    post,
    list,
    remove,
    batchDelete,
    batchUpdate
};