import request from '@/utils/request'
function batch (params) {
  return request({
    url: `/moonlight-device/batch`,
    method: 'post',
    data: params
  })
}
function batchDelete (params) {
  return request({
    url: `/moonlight-device/batchDelete`,
    method: 'post',
    data: params
  })
}
function list (params) {
  const qsParams = new URLSearchParams();
  for (var key in params) {
    qsParams.append(key,params[key])
  }
  return request({
    url: `/moonlight-device?${qsParams.toString()}`,
    method: 'get',
    data: params
  })
}
function search (params) {
  return request({
    url: `/moonlight-device/search`,
    method: 'post',
    data: params
  })
}
function add (params) {
  return request({
    url: `/moonlight-device`,
    method: 'post',
    data: params
  })
}
function update (params) {
  return request({
    url: `/moonlight-device/${params.id}`,
    method: 'put',
    data: params
  })
}
function remove (tag_id) {
  return request({
    url: `/moonlight-device/${tag_id}`,
    method: 'delete'
  })
}
export default {
    list,
    add,
    update,
    remove,
    search,
    batch,
    batchDelete
}
