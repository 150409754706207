/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  subCategories: [],
  total:0,
  max:10,
  offset:0,
};
