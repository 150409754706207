/* ============
 * Getters for the global module
 * ============
 *
 * The getters that are available on the
 * global module.
 */
export const photoURL = (state) => {
  return state.photoURL
}
export const isSignIn = (state) => {
  return state.isSignIn
}
export const email = (state) => {
  return state.email
}
export const displayName = (state) => {
  return state.displayName
}
export const user_id = (state) => {
  return state.user_id
}
export default {
  photoURL,
  isSignIn,
  email,
  displayName,
  user_id
}
