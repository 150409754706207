import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
// const Dashboard = () => import('@/views/Dashboard')

// const Colors = () => import('@/views/theme/Colors')
// const Typography = () => import('@/views/theme/Typography')

// const Charts = () => import('@/views/charts/Charts')
// const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
// const Cards = () => import('@/views/base/Cards')
// const Forms = () => import('@/views/base/Forms')
// const Switches = () => import('@/views/base/Switches')
// const Tables = () => import('@/views/base/Tables')
// const Tabs = () => import('@/views/base/Tabs')
// const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
// const Carousels = () => import('@/views/base/Carousels')
// const Collapses = () => import('@/views/base/Collapses')
// const Jumbotrons = () => import('@/views/base/Jumbotrons')
// const ListGroups = () => import('@/views/base/ListGroups')
// const Navs = () => import('@/views/base/Navs')
// const Navbars = () => import('@/views/base/Navbars')
// const Paginations = () => import('@/views/base/Paginations')
// const Popovers = () => import('@/views/base/Popovers')
// const ProgressBars = () => import('@/views/base/ProgressBars')
// const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
// const StandardButtons = () => import('@/views/buttons/StandardButtons')
// const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
// const Dropdowns = () => import('@/views/buttons/Dropdowns')
// const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
// const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
// const Brands = () => import('@/views/icons/Brands')
// const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
// const Alerts = () => import('@/views/notifications/Alerts')
// const Badges = () => import('@/views/notifications/Badges')
// const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
// const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

const AudioList = ()=> import('@/views/audio/List');
const TagList = ()=> import('@/views/tag/List');
const OrganizationList = ()=> import('@/views/organization/List');
const MoonlightDeviceList = ()=> import('@/views/moonlight-device/List');
const Category = ()=> import('@/views/category/List');
const GroupList = ()=> import('@/views/group/List');
const GroupAudioApproval = ()=> import('@/views/group-audio-approval/List');
const AccountList = ()=> import('@/views/account/List');
const AccountImport = ()=> import('@/views/account/Import');
const AssignList = ()=> import('@/views/assign/List');
const ReportCjxf = ()=> import('@/views/account/ReportCjxf');
Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/audio',
      name: '首頁',
      component: TheContainer,
      children: [
        {
          path: 'category',
          name: '分類管理',
          component: Category,
          meta: { roles: ['admin'] }
        },
        {
          path: 'audio',
          name: '音檔管理',
          component: AudioList,
          meta: { roles: ['admin','org-admin','org-member']}
        },
        {
          path: 'tag',
          name: '標籤管理',
          component: TagList,
          meta: { roles: ['admin']}
        },
        {
          path: 'group',
          name: '群組管理',
          redirect: '/group/',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/',
              component: GroupList,
              meta: { roles: ['admin','org-admin','org-member']},
            },
            {
              path: 'group-audio-approval/:id',
              name: '音檔審核',
              component: GroupAudioApproval,
              meta: { roles: ['admin','org-admin','org-member']},
              props: true ,
            }
          ]
        },
        {
          path: 'account',
          name: '人員管理',
          redirect: '/account/list',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: '列表',
              props: true ,
              component: AccountList,
              meta: { roles: ['admin','org-admin','org-member']},
            },
            {
              name: '匯入',
              path: 'import',
              component: AccountImport,
              meta: { roles: ['admin','org-admin']},
            },
            {
              name: '月映心湖統計',
              path: 'ReportCjxf',
              component: ReportCjxf,
              meta: { roles: ['admin']},
            }
          ]
        },
        {
          path: 'organization',
          name: '單位管理',
          component: OrganizationList,
          meta: { roles: ['admin']}
        },
        {
          path: 'moonlight-device',
          name: '月光機管理',
          component: MoonlightDeviceList,
          meta: { roles: ['admin','org-admin','org-member']}
        },
        
        {
          path: 'users',
          meta: { roles: ['admin'] ,abel: 'Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Users,
            },
            {
              path: ':id',
              meta: { label: 'User Details', roles: ['admin']},
              name: 'User',
              component: User,
            },
          ]
        },
        {
          path: 'assign',
          name: '個人授權',
          component: AssignList,
          meta: { roles: ['admin']}
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
      ]
    }
  ]
}

