import {
  photoURL,isSignIn,email,displayName,isAdmin,
  access_token,
  access_token_exp,
  user_id,
  role
} from './mutation-types';

export default {
  [photoURL] (state,
    photoURL
  ) {
    state['photoURL'] = photoURL;
  },
  [isSignIn] (state,
    isSignIn
  ) {
    state['isSignIn'] = isSignIn;
  },
  [email] (state,
    email
  ) {
    state['email'] = email;
  },
  [displayName] (state,
    displayName
  ) {
    state['displayName'] = displayName;
  },
  [isAdmin] (state,
    isAdmin
  ) {
    state['isAdmin'] = isAdmin;
  },
  [access_token] (state,
    access_token
  ) {
    state['access_token'] = access_token;
  },
  [access_token_exp] (state,
    access_token_exp
  ) {
    state['access_token_exp'] = access_token_exp;
  },
  [user_id] (state,
    user_id
  ) {
    state['user_id'] = user_id;
  },
  [role] (state,
    role
  ) {
    state['role'] = role;
  },
};
