import {
  SET_LIST,
  SET_TOTAL,
  SET_OFFSET,
  SET_MAX,
  SET_ORDER,
  SET_SORT,
  SET_IS_APPROVED
} from './mutation-types';

export default {
  [SET_LIST] (state,
    items
  ) {
    state['audios'] = [
      ...items
    ];
  },
  [SET_TOTAL] (state,
    total
  ) {
    state['total'] = total;
  },
  [SET_OFFSET] (state,
    offset
  ) {
    state['offset'] = offset;
  },
  [SET_MAX] (state,
    max
  ) {
    state['max'] = max;
  },
  [SET_SORT] (state,
    sort
  ) {
    state['sort'] = sort;
  },
  [SET_ORDER] (state,
    order
  ) {
    state['order'] = order;
  },
  [SET_IS_APPROVED] (state,
    params
  ) {
    const audios = state['audios'];
    const index = audios.findIndex(row => row.id === params.id);
    audios[index].is_approved = params.is_approved;
    state['audios'] = [ ...audios];
  },
};
