import axios from 'axios'
import {
  Message
} from 'element-ui'
import store from '../store';
// import store from '../store'
// import * as firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';
// import { getToken } from '@/utils/auth'
// 创建axios实例
const service = axios.create({
  // baseURL: '/api_test', // api的base_url
 baseURL: process.env.VUE_APP_APIURL, // api的base_url
  //baseURL: 'https://api.treasure.gebis.net',
  timeout: 180000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  // config.headers['Content-Type'] = 'multipart/form-data'
  // config.headers['Content-Type'] = 'application/json'
  if(config.url != '/auth/refresh' && new Date().getTime()/1000 +60> store.state.auth.access_token_exp && store.state.auth.access_token_exp > 0) {
    return store.dispatch('auth/refresh',null,{root: true}).then(res=>{
      if(store.state.auth.access_token) {
        config.headers['Authorization'] = `Bearer ${store.state.auth.access_token}`;
      }
      return config;
    })
  } else {
    if(store.state.auth.access_token) {
      config.headers['Authorization'] = `Bearer ${store.state.auth.access_token}`;
    }
    
    return config;
  }
  
}, error => {
  console.log(error) // for debug
  Promise.reject(error)
})
// respone拦截器
service.interceptors.response.use(res => {
    return res
  },
  error => {
    console.log('err' + error) // for debug
    if (error.response?.data?.message) {
      Message({
        message: error.response.data.message,
        type: 'error'
      })
    } else {
      Message({
        message: error.message,
        type: 'error'
      })
    }

    return error
  }
)

export default service
