/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

// import Vue from 'vue';
// import store from '@/store';
// import * as types from './mutation-types';
/*
import API from '@/api/';
export const fetchPlanList = ({
  commit
}) => {
  return new Promise((resolve) => {
    API.plan.fetchPlanList().then(res => {
      if (res.status === 200) {
        commit(`plan/SET_PLANLIST`, {
          items: res.data
        }, {
          root: true
        })
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }
    })
  });
};
*/
import API from '@/api/'

export const list = ({ commit },params) => {
  return new Promise((resolve) => {
    API.groupAudioApproval.list(params).then(res => {
      if (res.status === 200) {
        commit(`groupAudioApproval/SET_LIST`, res.data[0], {
          root: true
        });
        commit(`groupAudioApproval/SET_TOTAL`, res.data[1], {
          root: true
        });
        commit(`groupAudioApproval/SET_OFFSET`, params.offset, {
          root: true
        });
        commit(`groupAudioApproval/SET_MAX`, params.max, {
          root: true
        });
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}

export const update = ({ commit },params) => {
  return new Promise((resolve) => {
    API.groupAudioApproval.update(params).then(res => {
      if (res.status === 200) {
        commit('groupAudioApproval/SET_IS_APPROVED',{id: params.id, is_approved: params.is_approved?params.is_approved:null},{root: true})
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}

export default {
  list,
  update,
};