export const photoURL = 'photoURL';
export const isSignIn = 'isSignIn';
export const email = 'email';
export const displayName = 'displayName';
export const isAdmin = 'isAdmin';
export const access_token = 'access_token';
export const access_token_exp = 'access_token_exp';
export const user_id = 'user_id';
export const role = 'role';
export default {
  photoURL,
  isSignIn,
  email,
  displayName,
  isAdmin,
  access_token,
  access_token_exp,
  user_id,
  role
};
