/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import API from '@/api/'
export const clear = ({ commit }) => {
  return new Promise((resolve) => {
    commit(`account/CLEAR`,null, {
      root: true
    });
    resolve();
  })
}
export const list = ({ commit },params) => {
  return new Promise((resolve) => {
    API.account.list(params).then(res => {
      if (res.status === 200) {
        commit(`account/SET_LIST`, res.data[0], {
          root: true
        });
        commit(`account/SET_TOTAL`, res.data[1], {
          root: true
        });
        commit(`account/SET_OFFSET`, params.offset, {
          root: true
        });
        commit(`account/SET_MAX`, params.max, {
          root: true
        });
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export const add = ({ commit },params) => {
  return new Promise((resolve, reject) => {
    API.account.add(params).then(res => {
      if (res.status === 201) {
        resolve(res)
      } else {
        reject(res)
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    }).catch(error=>reject(error));
  })
}
export const update = ({ commit },params) => {
  return new Promise((resolve) => {
    API.account.update(params).then(res => {
      if (res.status === 200) {
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export const remove = ({ commit },params) => {
  return new Promise((resolve) => {
    API.account.remove(params).then(res => {
      if (res.status === 200) {
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export const batch = ({ commit }, params) => {
  return new Promise((resolve) => {
    API.account.batch(params).then(res => {
      resolve(res);
    })
  })
}
export const batchDelete = ({ commit }, params) => {
  return new Promise((resolve) => {
    API.account.batchDelete(params).then(res => {
      resolve(res);
    })
  })
}
export const batchUpdate = ({ commit }, params) => {
  return new Promise((resolve) => {
    API.account.batchUpdate(params).then(res => {
      resolve(res);
    })
  })
}
export default {
  list,
  add,
  update,
  remove,
  batch,
  batchDelete,
  clear,
  batchUpdate
};