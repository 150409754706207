import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import jwt from 'jsonwebtoken';

import store from './store';
import locale from 'element-ui/lib/locale/lang/zh-TW'
import './plugins/Vuedals';

const access_token = localStorage.getItem('access_token');
if(access_token) {
  const decoded = jwt.decode(access_token);
  store.commit('auth/access_token',localStorage.getItem('access_token'),{root: true});
  store.commit('auth/isSignIn',true,{root: true});
  store.commit('auth/isAdmin',decoded.isAdmin,{root: true});
  store.commit('auth/access_token_exp',decoded.exp,{root: true});
  store.commit('auth/user_id',decoded.sub,{root: true});
  store.commit('auth/displayName',decoded.displayName,{root: true});
  store.commit('auth/role',decoded.role,{root: true});
}


router.beforeEach((to, from, next) => {
  //Loading.service({ fullscreen: true })
  if(store.state.auth.isSignIn || to.name === "Login"){
    const roles = to.matched.flatMap(record => record.meta.roles ? record.meta.roles: []);
    if (roles.length > 0) {
        if(roles.includes(store.state.auth.role)) {
          next();
        } else {
          next('/pages/404');
        }
      } else {
        next();
      }
    }else{
      next('/pages/login');
    }
 })
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(ElementUI)
new Vue({
  el: '#app',
  router,
  icons,
  store,
  template: '<App/>',
  components: {
    App
  },
})
