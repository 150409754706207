/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

// import Vue from 'vue';
// import store from '@/store';
// import * as types from './mutation-types';
/*
import API from '@/api/';
export const fetchPlanList = ({
  commit
}) => {
  return new Promise((resolve) => {
    API.plan.fetchPlanList().then(res => {
      if (res.status === 200) {
        commit(`plan/SET_PLANLIST`, {
          items: res.data
        }, {
          root: true
        })
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }
    })
  });
};
*/
import API from '@/api/'
export const list = ({ commit },params) => {
  return new Promise((resolve) => {
    API.user.list(params).then(res => {
      if (res.status === 200) {
        commit(`user/SET_LIST`, res.data.data, {
          root: true
        });
        commit(`user/SET_TOTAL`, res.data.total, {
          root: true
        });
        commit(`user/SET_OFFSET`, res.data.offset, {
          root: true
        });
        commit(`user/SET_MAX`, res.data.max, {
          root: true
        });
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export const add = ({ commit },params) => {
  return new Promise((resolve) => {
    API.user.add(params).then(res => {
      if (res.status === 201) {
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export const update = ({ commit },params) => {
  return new Promise((resolve) => {
    API.user.update(params).then(res => {
      if (res.status === 200) {
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export const remove = ({ commit },params) => {
  return new Promise((resolve) => {
    API.user.remove(params).then(res => {
      if (res.status === 200) {
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export const clear = ({ commit }) => {
  return new Promise((resolve) => {
    commit(`user/CLEAR`, {
      root: true
    });
    resolve();
  })
}
export default {
  list,
  add,
  update,
  remove,
  clear
};