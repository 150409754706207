/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  audios: [],
  total:0,
  max:10,
  offset:0,
  sort: 'is_approved',
  order: 'ascending'
};
