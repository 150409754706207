/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

// import Vue from 'vue';
// import store from '@/store';
// import * as types from './mutation-types';
/*
import API from '@/api/';
export const fetchPlanList = ({
  commit
}) => {
  return new Promise((resolve) => {
    API.plan.fetchPlanList().then(res => {
      if (res.status === 200) {
        commit(`plan/SET_PLANLIST`, {
          items: res.data
        }, {
          root: true
        })
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }
    })
  });
};
*/

import API from '@/api/';
import jwt from 'jsonwebtoken';

export const logout = ({ commit },params) => {
  return new Promise((resolve) => {
    localStorage.removeItem('access_token')
    commit(`auth/isSignIn`, false, {
      root: true
    })
    commit(`auth/isAdmin`, false ,{
      root: true
    })
    commit(`auth/access_token`, '', {
      root: true
    })
    commit(`auth/access_token_exp`, 0, {
      root: true
    })
    commit(`auth/displayName`, '', {
      root: true
    })
    commit(`auth/user_id`, 0, {
      root: true
    })
    commit(`auth/role`, '', {
      root: true
    })
    commit(`account/CLEAR`, null, {
      root: true
    });
    commit(`audio/CLEAR`, null, {
      root: true
    });
    commit(`category/CLEAR`, null, {
      root: true
    });
    commit(`group/CLEAR`, null, {
      root: true
    });
    commit(`tag/CLEAR`, null, {
      root: true
    });
    commit(`user/CLEAR`, null, {
      root: true
    });
    resolve()
  })
}
export const login = ({ commit },params) => {
  return new Promise((resolve) => {
    API.auth.login(params).then(res => {
      if (res.status === 201) {
        const decoded = jwt.decode(res.data.access_token)
        localStorage.setItem('access_token',res.data.access_token)
        commit(`auth/isSignIn`, true, {
          root: true
        })
        commit(`auth/isAdmin`, decoded.isAdmin, {
          root: true
        })
        commit(`auth/access_token`, res.data.access_token, {
          root: true
        })
        commit(`auth/access_token_exp`, decoded.exp, {
          root: true
        })
        commit(`auth/displayName`, decoded.displayName, {
          root: true
        })
        commit(`auth/user_id`, decoded.sub, {
          root: true
        })
        commit(`auth/role`, decoded.role, {
          root: true
        })
        commit(`account/CLEAR`, null, {
          root: true
        });
        commit(`audio/CLEAR`, null, {
          root: true
        });
        commit(`category/CLEAR`, null, {
          root: true
        });
        commit(`group/CLEAR`, null, {
          root: true
        });
        commit(`tag/CLEAR`, null, {
          root: true
        });
        commit(`user/CLEAR`, null, {
          root: true
        });
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}

export const refresh = ({ commit },params) => {
  return new Promise((resolve) => {
    API.auth.refresh(params).then(res => {
      if (res.status === 201) {
        const decoded = jwt.decode(res.data.access_token)
        localStorage.setItem('access_token',res.data.access_token)
        commit(`auth/isSignIn`, true, {
          root: true
        })
        commit(`auth/isAdmin`, decoded.isAdmin, {
          root: true
        })
        commit(`auth/access_token`, res.data.access_token, {
          root: true
        })
        commit(`auth/access_token_exp`, decoded.exp, {
          root: true
        })
        commit(`auth/displayName`, decoded.displayName, {
          root: true
        })
        commit(`auth/user_id`, decoded.sub, {
          root: true
        })
        commit(`auth/role`, decoded.role, {
          root: true
        })
        resolve(res)
      } else {
        localStorage.removeItem('access_token')
        commit(`auth/isSignIn`, false, {
          root: true
        })
        commit(`auth/isAdmin`, false ,{
          root: true
        })
        commit(`auth/access_token`, '', {
          root: true
        })
        commit(`auth/access_token_exp`, 0, {
          root: true
        })
        commit(`auth/displayName`, '', {
          root: true
        })
        commit(`auth/user_id`, 0, {
          root: true
        })
        commit(`auth/role`, '', {
          root: true
        })
      }

    })
  }).catch(reason=>{
    localStorage.removeItem('access_token')
    commit(`auth/isSignIn`, false, {
      root: true
    })
    commit(`auth/isAdmin`, false ,{
      root: true
    })
    commit(`auth/access_token`, '', {
      root: true
    })
    commit(`auth/access_token_exp`, 0, {
      root: true
    })
    commit(`auth/displayName`, '', {
      root: true
    })
    commit(`auth/user_id`, 0, {
      root: true
    })
    commit(`auth/role`, '', {
      root: true
    })
  })
}

export const updatePassword = ({ commit },params) => {
  return new Promise((resolve) => {
    API.auth.updatePassword(params).then(res => {
      if (res.status === 200) {
        const decoded = jwt.decode(res.data.access_token)
        localStorage.setItem('access_token',res.data.access_token)
        commit(`auth/isSignIn`, true, {
          root: true
        })
        commit(`auth/isAdmin`, decoded.isAdmin, {
          root: true
        })
        commit(`auth/access_token`, res.data.access_token, {
          root: true
        })
        commit(`auth/access_token_exp`, decoded.exp, {
          root: true
        })
        commit(`auth/displayName`, decoded.displayName, {
          root: true
        })
        commit(`auth/user_id`, decoded.sub, {
          root: true
        })
        commit(`auth/role`, decoded.role, {
          root: true
        })
        resolve(res)
      } else {
        // Message({
        //   message: res.data.message,
        //   type: 'error',
        //   duration: 10 * 1000
        // })
      }

    })
  })
}
export default {
  login,
  refresh,
  logout,
  updatePassword
};
