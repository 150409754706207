import request from '@/utils/request'

function list (params) {
  const qsParams = new URLSearchParams();
  for (var key in params) {
    qsParams.append(key,params[key])
  }
  return request({
    url: `/organization?${qsParams.toString()}`,
    method: 'get',
    data: params
  })
}
function get (organization_id) {
  return request({
    url: `/organization/${organization_id}`,
    method: 'get'
  })
}
function getWithTags (organization_id) {
  return request({
    url: `/organization/${organization_id}?relation=tags`,
    method: 'get'
  })
}
function getWithGroups (organization_id) {
  return request({
    url: `/organization/${organization_id}?relation=groups`,
    method: 'get'
  })
}
function search (params) {
  return request({
    url: `/organization/search`,
    method: 'post',
    data: params
  })
}
function add (params) {
  return request({
    url: `/organization`,
    method: 'post',
    data: params
  })
}
function update (params) {
  return request({
    url: `/organization/${params.id}`,
    method: 'put',
    data: params
  })
}
function remove (organization_id) {
  return request({
    url: `/organization/${organization_id}`,
    method: 'delete'
  })
}
export default {
    list,
    search,
    add,
    update,
    remove,
    get,
    getWithTags,
    getWithGroups
}
