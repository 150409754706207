import audio from './audio';
import tag from './tag';
import organization from './organization';
import moonlightDevice from './moonlight-device';
import category from './category';
import subCategory from './sub-category';
import auth from './auth';
import user from './user';
import group from './group';
import groupAudioApproval from './group-audio-approval'
import account from './account'
import audioAccount from './audio-account'
export default {
  audio,
  tag,
  organization,
  moonlightDevice,
  category,
  subCategory,
  auth,
  user,
  group,
  groupAudioApproval,
  account,
  audioAccount
  // order
}
