/* ============
 * Getters for the global module
 * ============
 *
 * The getters that are available on the
 * global module.
 */
export const groups = (state) => {
  return state.groups
}
export const total = (state) => {
  return state.total
}
export const offset = (state) => {
  return state.offset
}
export const max = (state) => {
  return state.max
}
export const getGroupById = (state) => (id) => {
  return state.groups.find(group => group.id === id)
}
export const sort = (state) => {
  return state.sort
}
export const order = (state) => {
  return state.order
}
export default {
  groups,
  total,
  offset,
  max,
  getGroupById,
  sort,
  order
}
