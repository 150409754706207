/* ============
 * Getters for the global module
 * ============
 *
 * The getters that are available on the
 * global module.
 */
export const accounts = (state) => {
  return state.accounts
}
export const total = (state) => {
  return state.total
}
export const offset = (state) => {
  return state.offset
}
export const max = (state) => {
  return state.max
}
export const getAccountById = (state) => (id) => {
  return state.accounts.find(account => account.id === id)
}
export const sort = (state) => {
  return state.sort
}
export const order = (state) => {
  return state.order
}
export default {
  accounts,
  total,
  offset,
  max,
  getAccountById,
  sort,
  order
}
