/* ============
 * Getters for the global module
 * ============
 *
 * The getters that are available on the
 * global module.
 */
function findNode(nodes, id) {
  for(var node of nodes){
    if (node.id === id){
      return node;
    } else {
      if (node.children.length > 0) {
        var r =  findNode(node.children,id);
        if(r) {
          return r;
        }
      }
    }
  }
  return null;
}
export const categories = (state) => {
  return state.categories
}
export const total = (state) => {
  return state.total
}
export const offset = (state) => {
  return state.offset
}
export const max = (state) => {
  return state.max
}
export const getCategoryById = (state) => (id) => {
  return findNode(state.categories,id);
}
export default {
  categories,
  total,
  offset,
  max,
  getCategoryById
}
